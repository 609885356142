import React from "react"
import { graphql, Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit, faCheckSquare } from "@fortawesome/free-solid-svg-icons"

import Seo from "../components/seo"

const aboutPage = ({ data, location }) => (
<Layout>
  <Seo
    pagetitle="フロントセンセイについて"
    pagedesc="HTML/CSS/JavaScriptの初心者・中級者向け情報、Webサイト制作やデザインに関する情報、ビジネスに役立つ情報などを発信するブログです。"
    pagepath={location.pathname}
    pageimg={data.about.childImageSharp.original.src}
    pageimgw={data.about.childImageSharp.original.width}
    pageimgh={data.about.childImageSharp.original.height}
  />
  <div>
    <div className="eyecatch">
      <figure>
        <StaticImage
          src="../images/about.jpg"
          alt="ノートとラップトップ"
          layout="fullWidth"
        />
      </figure>
    </div>
    <article className="content">
      <div className="container">
        <h1 className="bar">フロントセンセイについて</h1>
        <aside className="info">
          <div className="subtitle">
            <FontAwesomeIcon icon={faEdit} />
            フロントセンセイとは
          </div>
        </aside>
        <div className="postbody">
          <p>HTML/CSS/JavaScriptの初心者・中級者向け情報、Webサイト制作やデザインに関する情報、ビジネスに役立つ情報などを発信するブログです。</p>
          <p><a href="https://amzn.to/2VNmffL" target="_blank" rel="noopener noreferrer">Webサイト高速化のための 静的サイトジェネレーター活用入門</a>で作成したサンプルサイトをベースに、独自にカスタマイズしています。<br />大きくカスタマイズした箇所は以下の通り。</p>
          <ul>
            <li><Link to={`/blog/post/how-to-use-scss-on-gatsby/`}>CSSをSCSSに変更</Link></li>
            <li><Link to={`/blog/post/syntax-highlighting-on-contentful/`}>コードブロックのシンタックスハイライト表示</Link></li>
            <li><Link to={`/blog/post/external-link-rich-text-contentful/`}>外部リンクは別タブで開く</Link></li>
            <li><Link to={`/blog/post/display-category-customize-of-gatsby-blog/`}>記事一覧にカテゴリーの表示</Link></li>
            <li><Link to={`/blog/post/related-posts-customize-of-gatsby-blog/`}>記事ページに関連記事の表示</Link></li>
            <li><Link to={`/blog/post/contentful-search-customize-of-gatsby-blog/`}>サイト内検索</Link></li>
            <li><Link to={`/blog/post/contentful-toc-customize-of-gatsby-blog/`}>記事ページに目次の表示</Link></li>
            <li><Link to={`/blog/post/sns-share-customize-of-gatsby-blog/`}>記事ページにSNSシェアの表示</Link></li>
            <li>記事一覧に投稿日の表示 &gt; 記事一覧に投稿日を表示、更新された記事には更新日を表示</li>
            <li>記事ページに更新日の表示 &gt; 投稿日を基本として更新された記事には更新日を表示</li>
          </ul>
          <p>今後カスタマイズしたいこと。</p>
          <ul>
            <li>お問い合わせフォーム</li>
          </ul>

          <h2>
            <FontAwesomeIcon icon={faCheckSquare} />
            広告について
          </h2>
          <h3>Amazonアソシエイト</h3>
          <p>フロントセンセイは、Amazon.co.jpを宣伝しリンクすることによってサイトが紹介料を獲得できる手段を提供することを目的に設定されたアフィリエイト宣伝プログラムである、Amazonアソシエイト・プログラムの参加者です。</p>
          <h3>Google AdSense</h3>
          <p>フロントセンセイは、Google AdSenseを利用し、Cookieやウェブビーコンを使用して広告を掲載しています。<br />Google AdSenseはインターネットにおけるユーザーの過去のアクセス情報に基づいて広告を配信します。<br />Cookieによる情報提供を望まない場合、ユーザーは<a href="https://policies.google.com/technologies/ads" target="_blank" rel="noopener noreferrer">こちら</a>にアクセスし、Cookieオプションを使用しないように設定することができます。</p>

          <h2>
            <FontAwesomeIcon icon={faCheckSquare} />
            リンクについて
          </h2>
          <p>フロントセンセイは、リンクフリーです。<br />記事、カテゴリ、トップページなど正規に公開しているページであれば、どこにリンクしていただいても構いませんが、インラインフレームの使用や画像・ファイルなどへの直リンクは禁止とさせていただきます。</p>

          <h2>
            <FontAwesomeIcon icon={faCheckSquare} />
            免責事項
          </h2>
          <p>フロントセンセイをご利用になったことにより生じるいかなる損失・損害について責任を負いかねます。<br />ご了承ください。</p>

        </div>
      </div>
    </article>
  </div>
</Layout>
)

export const query = graphql`
query {
  about: file(relativePath: {eq: "about.jpg"}) {
    childImageSharp {
      original {
        src
        height
        width
      }
    }
  }
}
`

export default aboutPage